import {configWithAuth, dataServiceQuery} from '@/store/api_configs'
import IndexedDb from '@/utils/WorksheetDB'
import axios from 'axios'
import OfflineBatteryItem from './OfflineBatteryItem'
import OfflineChargerItem from './OfflineChargerItem'
import useLocalStorage from "@/composables/useLocalStorage";
import { STATUS } from "@/repository/Contants";
import { alertController } from '@ionic/vue';
import {useDeviceLocationTags} from "@/composables/useDeviceLocationTags";

const {parseUser} = useLocalStorage()

const deviceLocationTags = useDeviceLocationTags()
/**
 * OfflineWorksheet class represents an entire worksheet in memory and
 * manage actions on it (download, upload, save, load)
 */
export default class OfflineWorksheet {

  indexedDb = null
  id = null
  worksheetData = {}
  batteryItems = []
  chargerItems = []
  workTimes = []

  lastSynced = null
  editedSinceLastSync = false
  recursive = false

  /**
   * Initialize worksheet
   * @param {*} id Id of worksheet
   * @param {*} indexedDb IndexedDB object for database management (auto generated if not set)
   * @param recursive
   */
  constructor(id, indexedDb = null, recursive = false) {
    if (id < 0) {
      this.id = id
      this.recursive = true
    } else {
      this.id = recursive ? (Math.abs(id) * -1) : id
      this.recursive = recursive
    }
    if (indexedDb)
      this.indexedDb = indexedDb
    else
      this.indexedDb = new IndexedDb()
  }

  /**
   * Returns if worksheet is able to edit
   * @returns {boolean}
   */
  calculateIfEditAllowed() {
    const userFromLocalStorage = parseUser()
    return this.worksheetData.preparedData.status < STATUS.CLOSED &&
      (this.worksheetData.preparedData.userAssignment.some(user => userFromLocalStorage.__uid === user.user?.id)
        || (JSON.parse(localStorage.getItem('service_app_user')).abilities['access-sysadmin']?true:false))
  }

  /**
   * Download and save just the signatures of worksheet (not saved in IndexedDb)
   */
  async downloadAndUpdateSignatures() {

    const tmp = (await axios(configWithAuth((this.recursive ? "POST" : "GET"), (this.recursive ? "worksheets/getLatestByRoot/" : "worksheets/") + Math.abs(this.id)))).data.data
    if (tmp.entity.signature) {
      this.worksheetData.entity.signature = tmp.entity.signature
    }
    if (tmp.entity.user_signature) {
      this.worksheetData.entity.user_signature = tmp.entity.user_signature
    }
  }

  /**
   * Download base data about worksheet
   */
  async downloadWorksheetData(storeOnlyId = false) {
    const response = (await axios(configWithAuth((this.recursive ? "POST" : "GET"), (this.recursive ? "worksheets/getLatestByRoot/" : "worksheets/") + Math.abs(this.id)))).data.data
    if (storeOnlyId) {
      if (this.worksheetData.entity.id == response.entity.id && this.worksheetData.entity.status != response.entity.status) {
        this.worksheetData.entity = response.entity
      } else {
        if(this.worksheetData.entity.id !== response.entity.id){
          this.worksheetData.entity.notes = response.entity.notes
          this.worksheetData.entity.client_notes = response.entity.client_notes
        }
        this.worksheetData.entity.id = response.entity.id
      }
      return;
    }
    this.worksheetData = response
    console.log("-> set location tags")
    deviceLocationTags?.setLocationTags(this.worksheetData.preparedData.locationTags)
    if (this.worksheetData.preparedData.workTimes) {
      this.workTimes = this.worksheetData.preparedData.workTimes
      delete this.worksheetData.preparedData.workTimes
    }
  }
  /**
   * Download base data about worksheet
   */
  async updateWorksheetDataFromCloud() {
    let response
    try {
      response = (await axios(configWithAuth((this.recursive ? "POST" : "GET"), (this.recursive ? "worksheets/getLatestByRoot/" : "worksheets/") + Math.abs(this.id),undefined,undefined, {},5000))).data.data
      await this.downloadWorksheetItems(true)
    } catch (error) {
      console.log(error)
      return
    }

    this.worksheetData = {
      ...response,
      entity:{
        ...response.entity,
        signature: this.worksheetData.entity.signature,
        user_signature: this.worksheetData.entity.user_signature,
        client_notes: this.worksheetData.entity.id === response.entity.id ? this.worksheetData.entity.client_notes : response.entity.client_notes,
        mailto: this.worksheetData.entity.mailto,
        notes: this.worksheetData.entity.id === response.entity.id ? this.worksheetData.entity.notes : response.entity.notes,
        signed_by_client_name: this.worksheetData.entity.signed_by_client_name,
        signed_by_user_name: this.worksheetData.entity.signed_by_user_name,
        is_signed_by_phone_connect: this.worksheetData.entity.is_signed_by_phone_connect,
      },
      preparedData:{
        ...response.preparedData,
        workTimes: this.worksheetData.preparedData.workTimes,
        additionalItems: this.worksheetData.preparedData.additionalItems,
        fileAttachments: this.worksheetData.preparedData.fileAttachments || []
      }
    }
    this.storeFullWorksheet()

  }

  /**
   * Download worksheet items and create the objects representing them
   */
  async downloadWorksheetItems(updateOnlyMissing = false) {
    const batteries = (await axios(dataServiceQuery("worksheet", "getWorksheetItemsWithPropsAndMeasurements",
      {query: Math.abs(this.worksheetData.entity?.id || this.id), withAllRelated: this.recursive}))).data.data
    const list = Object.values(batteries)
    if(updateOnlyMissing){
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element['device_type'] === 10 ){
          const foundBatteryItem = this.batteryItems.find(battery=>battery.id === element.id)
          if(!foundBatteryItem || (!foundBatteryItem.examinedProperties.is_finished && element.is_finished)){
            if(foundBatteryItem) {this.batteryItems.splice(this.batteryItems.indexOf(foundBatteryItem), 1)}
            this.batteryItems.push(new OfflineBatteryItem(element.id, parseInt(this.id), element, this.indexedDb, this))
          }
        }
        else if (element['device_type'] === 20){
          const foundChargerItem = this.chargerItems.find(charger=>charger.id === element.id)
          if(!foundChargerItem || (!foundChargerItem.examinedProperties.is_finished && element.is_finished)){
            if(foundChargerItem) {this.chargerItems.splice(this.chargerItems.indexOf(foundChargerItem), 1)}
            this.chargerItems.push(new OfflineChargerItem(element.id, parseInt(this.id), element, this.indexedDb, this))
          }
        }
      }
    } else {
      this.batteryItems = this.batteryItems.filter(battery=>battery.id<0) || []
      this.chargerItems = this.chargerItems.filter(charger=>charger.id<0) || []
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element['device_type'] === 10){
          this.batteryItems.push(new OfflineBatteryItem(element.id, parseInt(this.id), element, this.indexedDb, this))
        }
        else if (element['device_type'] === 20){
          this.chargerItems.push(new OfflineChargerItem(element.id, parseInt(this.id), element, this.indexedDb, this))
        }
      }
    }
  }

  /**
   * Load worksheet items from IndexedDB and create the objects representing them
   */
  async loadWorksheetItems() {
    this.batteryItems = []
    this.chargerItems = []
    const response = await this.indexedDb.getKeysByIndex("batteries", "worksheetId", this.id)
    for (let index = 0; index < response.length; index++) {
      const element = response[index];
      let elementFromDatabase = JSON.parse((await this.indexedDb.getItem("batteries", element)).item)
      elementFromDatabase = elementFromDatabase.batteryItemData || elementFromDatabase.chargerItemData
      if (elementFromDatabase['device_type'] === 10) {
        const newItem = new OfflineBatteryItem(element, parseInt(this.id), null, this.indexedDb, this)
        await newItem.loadBatteryItemData()
        this.batteryItems.push(newItem)
      } else if (elementFromDatabase['device_type'] === 20) {
        const newItem = new OfflineChargerItem(element, parseInt(this.id), null, this.indexedDb, this)
        await newItem.loadChargerItemData()
        this.chargerItems.push(newItem)
      }
    }
  }

  /*
  async loadChargerItems(){
      this.batteryItems = []
      const response = await this.indexedDb.getKeysByIndex("batteries", "worksheetId", this.id)
      response.forEach(element => {
          const newItem = new OfflineChargerItem(element, parseInt(this.id), null, this.indexedDb, this)
          newItem.loadChargerItemData()
          this.chargerItems.push(newItem)
      });
  }*/
  /**
   * Crate OfflineBatteryItem object from download data
   * @param {Object} downloadData raw worksheet item data from server
   * @returns reference to created OfflineBatteryItem
   */
  async createSingleBatteryItemFromDownloadData(downloadData) {
    const newBatteryItem = new OfflineBatteryItem(parseInt(downloadData.id), parseInt(this.id), downloadData, this.indexedDb, this)
    newBatteryItem.saveBatteryItemData()
    this.batteryItems.push(newBatteryItem)
    return newBatteryItem
  }

  /**
   * Crate OfflineBatteryItem object from download data
   * @returns reference to created OfflineBatteryItem
   * @param batteryData
   * @param image
   */
  async createTemporaryBatteryItemOffline(batteryData, image) {
    const indexes = await this.indexedDb.getIndexes('batteries')
    const minIndex = Math.min(...indexes)
    let newId
    if (minIndex < 0) {
      newId = minIndex - 1
    } else {
      newId = -1
    }
    const newBatteryItem = new OfflineBatteryItem(newId, parseInt(this.id), {
      device_type: 10,
      battery: batteryData,
      datasheet_images: image
    }, this.indexedDb, this)
    newBatteryItem.saveBatteryItemData()
    this.batteryItems.push(newBatteryItem)
    return newBatteryItem
  }

  /**
   * Crate OfflineBatteryItem object from download data
   * @returns reference to created OfflineBatteryItem
   * @param chargerData
   * @param image
   */
  async createTemporaryChargerItemOffline(chargerData, image) {
    const indexes = await this.indexedDb.getIndexes('batteries')
    const minIndex = Math.min(...indexes)
    let newId
    if (minIndex < 0) {
      newId = minIndex - 1
    } else {
      newId = -1
    }
    const newChargerItem = new OfflineChargerItem(newId, parseInt(this.id), {
      device_type: 20,
      charger: chargerData,
      datasheet_images: image
    }, this.indexedDb, this)
    newChargerItem.saveChargerItemData()
    this.chargerItems.push(newChargerItem)
    return newChargerItem
  }

  /**
   * Crate OfflineChargerItem object from download data
   * @param {Object} downloadData raw worksheet item data from server
   * @returns reference to created OfflineChargerItem
   */
  async createSingleChargerItemFromDownloadData(downloadData) {
    const newChargerItem = new OfflineChargerItem(parseInt(downloadData.id), parseInt(this.id), downloadData, this.indexedDb, this)
    newChargerItem.saveChargerItemData()
    this.chargerItems.push(newChargerItem)
    return newChargerItem
  }

  /**
   * Load base worksheet data from IndexedDB
   */
  async loadWorksheetData() {

    const databaseItem = JSON.parse((await this.indexedDb.getItem("worksheets", this.id)).item)
    this.worksheetData = databaseItem.worksheetData
    this.workTimes = databaseItem.workTimes
    this.lastSynced = databaseItem.lastSynced
    this.editedSinceLastSync = databaseItem.editedSinceLastSync
    deviceLocationTags?.setLocationTags(this.worksheetData.preparedData.locationTags)
    //console.log(this)
  }

  /**
   * Save base worksheet data to IndexedDB
   */
  async saveWorksheetData() {
    await this.indexedDb.storeItem("worksheets", JSON.stringify({
      worksheetData: this.worksheetData,
      workTimes: this.workTimes,
      lastSynced: this.lastSynced,
      editedSinceLastSync: this.editedSinceLastSync
    }), this.id)
    deviceLocationTags?.setLocationTags(this.worksheetData.preparedData.locationTags)
  }

  /**
   * Delete worksheet and it's all item from IndexedDB
   */
  async deleteWorksheetLocalData() {
    for (let i = 0; i < this.batteryItems.length; i++) {
      await this.batteryItems[i].deleteLocalData()
    }
    for (let i = 0; i < this.chargerItems.length; i++) {
      await this.chargerItems[i].deleteLocalData()
    }
    await this.indexedDb.deleteItem(this.id, 'worksheets')
  }

  /**
   * Delete specified battery from cloud and IndexedDB by id
   * @param {number} id id of battery item
   */
  async deleteBatteryItemFromWorksheetOnline(id) {
    const batteryIndex = this.batteryItems.findIndex(batteryItem => parseInt(batteryItem.id) === parseInt(id))
    console.log(batteryIndex)
    console.log(id)
    const battery = this.batteryItems[batteryIndex]
    let response
    if (!battery.temporaryOfflineBatteryItem) {
      response = await axios(configWithAuth('POST', 'worksheets/remove-device', {id: Math.abs(id)}))
    }
    if ((response && response.data) || battery.temporaryOfflineBatteryItem) {

      await battery.deleteLocalData()
      this.batteryItems = this.batteryItems.filter((item, index) => index != batteryIndex)
    }
  }

  /**
   * Delete specified charger from cloud and IndexedDB by id
   * @param {number} id id of charger item
   */
  async deleteChargerItemFromWorksheetOnline(id) {
    const chargerIndex = this.chargerItems.findIndex(chargerItem => chargerItem.id === parseInt(id))
    const charger = this.chargerItems[chargerIndex]
    let response
    if (!charger.temporaryOfflineChargerItem) {
      response = await axios(configWithAuth('POST', 'worksheets/remove-device', {id: Math.abs(id)}))
    }
    if ((response && response.data) || charger.temporaryOfflineChargerItem) {
      await charger.deleteLocalData()
      this.chargerItems = this.chargerItems.filter((item, index) => index != chargerIndex)

    }
  }

  /**
   * Download entire worksheet with all device from cloud and store it locally
   */
  async downloadAndStoreFullWorksheet() {
    await this.downloadWorksheetData()
    await this.downloadWorksheetItems()
    for (let i = 0; i < this.batteryItems.length; i++) {
      const element = this.batteryItems[i]
      await element.saveBatteryItemData()
      //await element.loadBatteryItemData()
    }
    for (let i = 0; i < this.chargerItems.length; i++) {
      const element = this.chargerItems[i]
      await element.saveChargerItemData()
      //await element.loadBatteryItemData()
    }
    await this.saveWorksheetData()
    this.lastSynced = Date.now()
  }

  /**
   * Store entire worksheet with all devices in IndexedDB
   */
  async storeFullWorksheet() {
    for (let i = 0; i < this.batteryItems.length; i++) {
      const element = this.batteryItems[i]
      await element.saveBatteryItemData()
      //await element.loadBatteryItemData()
    }
    for (let i = 0; i < this.chargerItems.length; i++) {
      const element = this.chargerItems[i]
      await element.saveChargerItemData()
      //await element.loadBatteryItemData()
    }
    await this.saveWorksheetData()
  }

  /**
   * Load entire worksheet from IndexedDb with all devices
   */
  async loadFullWorksheet() {
    try {
      await this.loadWorksheetData()
      await this.loadWorksheetItems()
    } catch {
      await this.downloadAndStoreFullWorksheet()
    }

  }

  /**
   * Upload all possible data about worksheet,
   * download updates and store updated data
   * @param {Store} store Indicates progress with loader component if provided
   * @param {I18n} i18n Required if store provided to translate status messages
   */
  async syncFullWorksheet(store = null, i18n = null) {
    try {
      await this.downloadWorksheetData(true)
    } catch (error) {
      console.log('Worksheet pre download failed')
    }
    let items = []
    for (let i = 0; i < this.batteryItems.length; i++) {
      const element = this.batteryItems[i]
      if(element.id < 0){continue}
      if (element.batteryItemData.isHistoryEntry || !element.batteryItemData.modified) {
        continue;
      }/* else if (this.worksheetData.entity['refill_only']) {
        items.push({
          worksheetItemId: element.id,
          refillComplete: element.examinedProperties['refill_complete']
        })
      }*/ else {
        items.push({
          measurements: {
            worksheetItemId: element.id,
            measurements: element.measurements
          },
          examinedProperties: element.examinedProperties,
          parts: {
            worksheetItemId: element.id,
            parts: element.getPartsForUpload()
          },
          device: {
            ...element.batteryItemData,
            parts: undefined,
            problemImages: undefined,
            dataTableImage: undefined
          },
          'datasheet_images': (element.batteryItemData.datasheet_images?.length) ? element.batteryItemData.datasheet_images : (element.batteryItemData.datasheet_images ? [element.batteryItemData.datasheet_images] : undefined)

        })
      }

      if (store) {
        store.dispatch('load/changeMessage', i18n.t('Uploading batteries'))
      }
    }
    for (let i = 0; i < this.chargerItems.length; i++) {
      const element = this.chargerItems[i]
      if(element.id < 0){continue}
      if (element.chargerItemData.isHistoryEntry || !element.chargerItemData.modified) {
        continue;
      }
      items.push({
        examinedProperties: element.examinedProperties,
        parts: {
          worksheetItemId: element.id,
          parts: element.getPartsForUpload(),
          device: {
            ...element.chargerItemData,
            parts: undefined,
            problemImages: undefined
          }
        },
        device: {
          ...element.chargerItemData,
          parts: undefined,
          problemImages: undefined,
          dataTableImage: undefined
        },
        'datasheet_images': (element.chargerItemData.datasheet_images?.length) ? element.chargerItemData.datasheet_images : (element.chargerItemData.datasheet_images ? [element.chargerItemData.datasheet_images] : undefined)
      })
      if (store) {
        store.dispatch('load/changeMessage', i18n.t('Uploading chargers'))
      }
    }
    try {
      await axios(configWithAuth('post', 'worksheets/save-cumulated-data', {
        items: items,
        workTimes: this.workTimes,
        worksheetId: Math.abs(this.worksheetData.entity.id || this.id),
        recursive: this.recursive,
        services: this.worksheetData.preparedData.additionalItems || [],
        worksheetData: {
          mailto: this.worksheetData.entity.mailto || null,
          signed_by_client_name: this.worksheetData.entity.signed_by_client_name || null,
          signed_by_user_name: this.worksheetData.entity.signed_by_user_name || null,
          signature: this.worksheetData.entity.signature || null,
          user_signature: this.worksheetData.entity.user_signature || null,
          notes: this.worksheetData.entity.notes || null,
          client_notes: this.worksheetData.entity.client_notes || null,
          fileAttachments: this.worksheetData.preparedData.fileAttachments || null
        }
      }))
    } catch (error) {
      const ionAlert = await alertController.create({
        header: 'Feltöltés sikertelen',
        subHeader: 'Ennek ellenére szeretné letölteni a munkalap legfrissebb verzióját?',
        message: '!!!Figyelem!!! Ez a művelet minden nem szinkronizált módosítást töröl a munkalapból! A hiba leírása: ' + (error.response?.data?.message || error.message),
        buttons: [
          {
            text: 'Nem',
            role: 'cancel',
            handler: () => {
              console.log('Sync canceled');
            },
          },
          {
            text: 'Igen',
            role: 'confirm',
            handler: async () => {
              store?.dispatch('load/startLoad', i18n.t("Download updated data"))
              try {
                await this.downloadWorksheetData()
                await this.downloadWorksheetItems()
                this.editedSinceLastSync = false
                this.lastSynced = Date.now()
                await this.saveWorksheetData()
              } catch (error) {
                alert('Letöltés sikertelen')
              } finally {
                store?.dispatch('load/stopLoad')
              }
            },
          },
        ],
      });

      await ionAlert.present();
      return false
    }
    for (let i = 0; i < this.batteryItems.length; i++) {
      await this.batteryItems[i].uploadPictures(false)
    }
    for (let i = 0; i < this.chargerItems.length; i++) {
      await this.chargerItems[i].uploadPictures(false)
    }
    if (store)
      store.dispatch('load/changeMessage', i18n.t("Download updated data"))
    await this.downloadWorksheetData()
    await this.downloadWorksheetItems()
    this.editedSinceLastSync = false
    this.lastSynced = Date.now()
    await this.saveWorksheetData()
    return true
  }

  /**
   * Finalize worksheet
   * @returns server's response for finalize request
   */
  async finalize() {
    const sync = await this.syncFullWorksheet()
    if (!sync) {
      return false;
    }
    const response = await axios(configWithAuth('post', 'worksheets/finalize', {
      worksheet: Math.abs(this.worksheetData.entity.id),
      partnerSignature: this.worksheetData.entity.signature,
      userSignature: this.worksheetData.entity.user_signature,
      partnerSignatureName: this.worksheetData.entity.signed_by_client_name,
      signedWithPhoneConnect: this.worksheetData.entity.is_signed_by_phone_connect,
      signAllBackwards: true,
      isRecursiveMode: true,
      recursiveGroupAllowed: true
    }))
    await this.saveWorksheetData()
    await this.downloadWorksheetData()
    return response
  }

  /**
   * Reopen worksheet
   * @returns server's response for finalize request
   */
  async reopen() {
    const response = await axios(configWithAuth('post', 'worksheets/reopen', {id: this.worksheetData.entity.id}))

    await this.downloadWorksheetData()
    await this.saveWorksheetData()
    return response
  }
}
